import React from "react";
import {Route, Redirect} from "react-router-dom";
import apiClient from "../http_utils";

export class ProtectedRoute extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: true,
            isLoggedIn: false
        };

        this.checkIfLoggedIn()
    }

    checkIfLoggedIn() {
        console.log("checkIfLoggedIn")
        apiClient.post("/api/user").then(response => {
            console.log(response)
            console.log(response.status)
            if (response.status === 200) {
                this.setState(() => ({isLoading: false, isLoggedIn: true}));

            } else {
                this.setState(() => ({isLoading: false, isLoggedIn: false}));
            }
        })
            .catch((error) => {
                console.error('Error:', error);
                this.setState(() => ({isLoading: false, isLoggedIn: false}));
            });
    }

    render() {
        const routeToRender = <Route {...this.props}/>
        return this.state.isLoading ? routeToRender :
            this.state.isLoggedIn ?
                routeToRender :
                <Redirect to={{ pathname: '/', state: { from: this.props.location } }} />
    }
}
