export const getBaseUrl = () => {
    let url;
    switch(process.env.NODE_ENV) {
        case 'production':
            url = process.env.REACT_APP_BACKEND_URL || 'https://api.cirun.io';
            break;
        case 'development':
        default:
            url = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';
    }
    return url;
}

export const getGHClientId = () => {
    let clientId;
    switch(process.env.NODE_ENV) {
        case 'production':
            clientId = process.env.REACT_APP_GH_CLIENT_ID || "Iv1.ee119c1f765c0c5d";
            break;
        case 'development':
        default:
            clientId = process.env.REACT_APP_GH_CLIENT_ID || "Iv1.9572271204512c44";
    }
    return clientId;
}

export default getBaseUrl;
