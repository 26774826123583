import apiClient from "../http_utils";
import getCookie from "./cookie_utils";
import getBaseUrl from "../views/utils";
import {getGHClientId} from "../views/utils";

const loginGitHub = () => {
    const CLIENT_ID = getGHClientId();
    window.location.assign(`https://github.com/login/oauth/authorize?client_id=${CLIENT_ID}`);
}
const fetchAccessToken = async (codeParam) => {
    const response = await apiClient.get(`${getBaseUrl()}/api/gh-login?code=${codeParam}`);
    if (response.status === 200) {
        const data = await response.data;
        console.log("data: ", data)
        const token = data.access_token;
        console.log("accessToken: ", token)
        return token;
    } else {
        console.log("error happened")
        return 1234;
    }
}


const fetchAccessTokenAfterGithubLogin = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const codeParam = urlParams.get('code')
    console.log("codeParam: ", codeParam)

    if (codeParam) {
        console.log("codeParam Delete: ", codeParam)
        window.history.pushState({}, document.title, "/" );
        console.log("fetching token")
        return await fetchAccessToken(codeParam);
    }
}

const logout = async () => {
    const response = await apiClient.get(`${getBaseUrl()}/api/gh-logout`);
    if (response.status === 200) {
        console.log("logged out")
        window.location.assign("/")
    } else {
        console.log("error happened")
    }
}

const loginAndSetUserInfo = (setUserInfo) => {
    fetchAccessTokenAfterGithubLogin().then((token) => {
            console.log("fetching user info, token: " + token)
            apiClient.defaults.headers['X-CSRF-TOKEN'] = getCookie('csrf_access_token');
            apiClient.post("/api/user").then(response => {
                console.log(response)
                console.log(response.status)
                if (response.status === 200) {
                    console.log("got user info")
                    setUserInfo(response.data)
                    console.log("set user info")
                } else {
                    console.log("cant fetch userinfo")
                }
            })
                .catch((error) => {
                    console.error('Error:', error);
                });
    })
}

export { fetchAccessTokenAfterGithubLogin, logout, loginGitHub, loginAndSetUserInfo };
