/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState} from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Container,
    Row,
    Col,
    Button,
} from "reactstrap";
// core components
import SubscriptionHeader from "components/Headers/SubscriptionHeader.js";
import apiClient from "../http_utils";


const Subscription = ({ userInfo }) => {

    const [subs, setSubs] = useState(null);

    useEffect(() => {
        getUserSubs().then(() => {})
    },[]);

    async function getUserSubs() {
        apiClient.get("/api/subscription").then(response => {
            // console.log("AWS Saved")
            console.log(response.data)
            setSubs(response.data)
            // setRepoActivateInProgress(false)
            if (response.data.hasOwnProperty('error')) {
                // setInvalidRepo(true)
                // setRepoActivated(false)
                // setRepoAddFailureMessage(response.data.error)
            } else {
                // getUserRepos().then(() => {})
                // setInvalidRepo(false)
                // setRepoActivated(true)
            }
        })

    }

    function getPlan() {
        return subs && subs.plan ? subs.plan : "Basic Open Source"
    }

    function renderPlanDetails() {
        return (
            <>
                <Row>
                    <Col>
                        <div
                            className="text-black-50 text-lg"
                            style={
                                {
                                    "fontFamily": "Bitter, serif",
                                }
                            }
                        >
                            <p>
                                You're subscribed to <u>{getPlan()}</u> plan.
                            </p>
                            <p>
                                Subscribe to another plan <a href="/#pricing">here.</a>
                            </p>
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Button
                        className="btn-lg mt-2"
                        color="primary"
                        outline
                        href="https://billing.stripe.com/p/login/28o5lLaQdfLd4jC5kk"
                    >
                        <span
                            className="btn-inner--text"
                            style={
                                {
                                    // "fontFamily": "Secular One, sans-serif",
                                }
                            }
                        >Manage Subscription</span>
                    </Button>
                </Row>
            </>
        );
    }
    return (
        <>
            <SubscriptionHeader userInfo={userInfo}/>
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>

                    <Col className="order-xl-1" xl="8">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Subscription</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <h6 className="heading-small text-muted mb-4">
                                        Subscription
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <FormGroup>
                                                {renderPlanDetails()}
                                            </FormGroup>
                                        </Row>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default Subscription;
