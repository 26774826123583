/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Profile from "views/Profile.js";
import Subscription from "./views/Subscription";
import Cloud from "./views/Cloud";
import Repositories from "./views/Repositories";
import API from "./views/API";

const routes = [
  // Admin Page
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "ni ni-single-02 text-green",
    component: Profile,
    layout: "/admin"
  },
  {
    path: "/subscription",
    name: "Subscription",
    icon: "ni ni-chart-bar-32 text-orange",
    component: Subscription,
    layout: "/admin"
  },
  {
    path: "/api",
    name: "API",
    icon: "fa fa-rocket text-blue",
    component: API,
    layout: "/admin"
  },
  {
    path: "/repositories",
    name: "Repositories",
    icon: "fab fa-github",
    component: Repositories,
    layout: "/admin"
  },
  {
    path: "/cloud",
    name: "Cloud",
    icon: "fas fa-cloud text-info",
    component: Cloud,
    layout: "/admin"
  },
  // {
  //   path: "/runner",
  //   name: "Create Runner",
  //   icon: "ni ni-spaceship text-info",
  //   component: CreateRunner,
  //   layout: "/admin"
  // },
  // Auth Page
];
export default routes;
