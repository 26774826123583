/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from 'react';
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Container,
    Row,
    Col,
    Spinner,
    Input,
    Label,
    Button,
} from "reactstrap";
// core components
import RepositoriesHeader from "components/Headers/RepositoriesHeader.js";
import axios from "axios";
import apiClient from "../http_utils";


const Repositories = ({ userInfo }) => {

    const [githubAppUrl, setGithubAppUrl] = useState("");

    const [repos, setRepos] = useState([]);
    const [repoLoading, setRepoLoading] = useState(false);
    const [repoActivateInProgress, setRepoActivateInProgress] = useState(false);
    const [repoActivated, setRepoActivated] = useState(false);
    const [activateRepo, setActivateRepo] = useState('');
    const [invalidRepo, setInvalidRepo] = useState(false);
    const [repoAddFailureMessage, setRepoAddFailureMessage] = useState("Invalid Repository");

    useEffect(() => {
        getUserRepos().then(() => {})

        let app_name = "cirun-dev"
        if (process.env.NODE_ENV === 'production') {
            app_name = "cirun-application"
        }
        setGithubAppUrl(`https://github.com/apps/${app_name}/installations/new`)

    },[]);

    async function getUserRepos() {
        setRepoLoading(true)
        apiClient.get("/api/repos").then(response => {
            console.log(response)
            console.log(response.status)
            if (response.status === 200) {
                const responseData = response.data
                setRepoLoading(false)
                setRepos(responseData['repos'])

            } else {
                console.log("Something wrong happened!")
            }
        })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    async function updateRepoState(event) {
        console.log(event.target)
        const selectedRepo = event.target.getAttribute("data-key")
        const isChecked = event.target.getAttribute("data-checked")
        const data = {
            'repository': selectedRepo,
            'active': isChecked === "0"
        }

        apiClient.post("/api/repos", JSON.stringify(data)).then(response => {
            console.log(response)
            console.log(response.status)
            if (response.status === 200) {
                setRepoLoading(false)

            } else {
                console.log("Something wrong happened!")
            }
        })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    function repoToggleOnChange(event) {
        console.log("On change")
        const currentValue = event.target.getAttribute("data-checked")
        const notCurrentValue = currentValue === "1"? "0": "1"
        console.log("currentValue: "+ currentValue)
        console.log("notCurrentValue: "+ notCurrentValue)
        event.target.setAttribute("data-checked", notCurrentValue)
    }

    function repoToggle() {
        let options = []

        if (repos && repos.length > 0) {
            console.log("repos")
            console.log(repos)
            options = repos.map((repo, index) => (
                <Row key={index} className="mt-1">
                    <Col lg="1" xs="4">

                        <Label className="custom-toggle lg">
                            {
                                repo.active ?
                                    <Input
                                        type="checkbox"
                                        data-key={repo.repository}
                                        data-checked="1"
                                        defaultChecked
                                        onClick={updateRepoState}
                                        onChange={repoToggleOnChange}
                                    /> :
                                    <Input
                                        data-key={repo.repository}
                                        type="checkbox"
                                        data-checked="0"
                                        onClick={updateRepoState}
                                        onChange={repoToggleOnChange}
                                    />
                            }

                            <span className="custom-toggle-slider rounded-circle" />
                        </Label>

                    </Col>
                    <Col xs="4" >
                        <a href={`https://github.com/${repo.repository}`} target="_blank">
                            <Button className={"btn-icon btn-3"} color={activateRepo === repo.repository ? "success": "secondary" } outline type="button">
                                {/*<span className="btn-inner--icon"><i className="fab fa-github"/></span>*/}
                                <span className="btn-inner--text">{repo.repository}</span>
                            </Button>
                        </a>
                        {/*<p> {repo.repository} </p>*/}
                    </Col>

                </Row>
            ))
        }

        return options
    }
    function renderConnectGithub() {
        return (
            <>
                <Row>
                    <div className="icon rounded-circle mr-4 icon-lg">
                        <i className="fab fa-github"/>
                    </div>

                    <a href={githubAppUrl} target="_blank">
                        <Button className={"btn-icon btn-3"} color="primary" outline type="button">
                            <span className="btn-inner--icon"><i className="fab fa-github"/></span>
                            <span className="btn-inner--text">Install App on Github</span>
                        </Button>
                    </a>
                </Row>
            </>
        );
    }

    function renderRepositories() {
        return <Form>
            <div className="pl-lg-4">
                <Row>
                    <Col lg="12">
                        <h6 className="heading-small text-muted mb-4">
                            Step 1: Connect Github
                            <p className="text-sm text-muted text-lowercase">
                                Install Cirun Github Application on your repositories
                            </p>
                        </h6>
                        <FormGroup>
                            {renderConnectGithub()}
                        </FormGroup>

                    </Col>
                    <Col>
                        <h6 className="heading-small text-muted mb-4">
                            Step 2: Activate Cirun for repositories
                            <p className="text-sm text-muted text-lowercase">
                                Enter a repository name to activate Cirun on it.
                            </p>
                            {renderActivateRepository()}
                        </h6>
                        <FormGroup>
                            {
                                !repoLoading ? repoToggle():
                                <Row>
                                    <Col lg="4">Fetching Repositories from GitHub ...</Col>
                                    <Spinner animation="border" role="status"> Fetching.. </Spinner>
                                </Row>
                            }

                        </FormGroup>

                    </Col>
                </Row>
            </div>

        </Form>
    }

    function renderCardBody() {
        let renderObject = renderRepositories()
        return <CardBody>{renderObject}</CardBody>
    }

    function renderRepoAddFailureMessage() {
        return (
            <>
                <p className="text-lowercase text-danger text-danger">
                    Repository Activation Failed
                </p>
                <p className="text-lowercase">
                    {repoAddFailureMessage}
                </p>
                {/*<p className="text-lowercase">*/}
                {/*    2. Cirun is <span className="font-weight-bold">not installed</span> on it (See Step 1 above) or*/}
                {/*</p>*/}
                {/*<p className="text-lowercase">3. You do not have <span*/}
                {/*    className="font-weight-bold">"admin"</span> permissions to it.</p>*/}
            </>

        )
    }

    function renderActivateRepository() {
        return (
            <>
                <Row>

                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            {/*<Label for="accessKey"><i className="ni ni-badge mr-2"/>Access Key</Label>*/}
                            <Input
                                className="form-control-alternative"
                                id="input-access-key"
                                placeholder="owner/repo"
                                type="text"
                                onChange={event => setActivateRepo(event.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        {renderActivateRepoButton()}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>
                            {invalidRepo ? renderRepoAddFailureMessage() : ''}
                            {
                                repoActivated ? <p className="text-lowercase text-success">Repository activated</p> : ''
                            }
                        </div>
                    </Col>
                </Row>
            </>
        );
    }

    function handleRepoActivate() {
        if (!activateRepo) {
            return
        }
        setRepoActivateInProgress(true)
        console.log(activateRepo)
        apiClient.post("/api/repos", {
            'repository': activateRepo,
            'active': true
        }).then(response => {
            console.log("AWS Saved")
            console.log(response.data)
            setRepoActivateInProgress(false)
            if (response.data.hasOwnProperty('error')) {
                setInvalidRepo(true)
                setRepoActivated(false)
                setRepoAddFailureMessage(response.data.error)
            } else {
                getUserRepos().then(() => {})
                setInvalidRepo(false)
                setRepoActivated(true)
            }
        })
    }

    function renderActivateRepoButton() {
        if (repoActivateInProgress) {
            return <Spinner animation="border" role="status"> </Spinner>
        } else {
            const button_cls = activateRepo ? "" : "disabled"
            return (
                <>
                <div>
                    <Button className={"btn-icon btn-3 " + button_cls} onClick={handleRepoActivate} color="primary"
                            type="button">
                        <span className="btn-inner--icon"><i className="fab fa-github"/></span>
                        <span className="btn-inner--text">{repoActivated ? "Activated!": "Activate"}</span>
                    </Button>
                    {repoActivated ? <i className="text-green fas fa-check"/>: ""}
                </div>
                </>
            )
        }
    }

    return (
        <>
            <RepositoriesHeader />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="10">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Repositories</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>{renderCardBody()}</CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}


export default Repositories;
