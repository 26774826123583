import React, {useEffect, useState} from "react";

import {
    Card,
    CardHeader,
    Table,
    Container,
    Row,
    Badge,
    Spinner, Button,
    Form,
    CardBody,
    DropdownItem,
    Col,
    FormGroup,
    Input
} from "reactstrap";


import APIHeader from "components/Headers/APIHeader.js";
import apiClient from "../http_utils";

const API = ({userInfo}) => {

    const [apiKey, setApiKey] = useState(null);
    const [createdToken, setCreatedToken] = useState(null);
    const [apiKeyCreationInProgress, setApiKeyCreationInProgress] = useState(null);
    const [apiKeyCreated, setApiKeyCreated] = useState(null);
    const [apiKeys, setApiKeys] = useState([]);
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        console.log("start")
        apiClient.get("/api/token").then(response => {
            console.log("Getting API Keys")
            console.log(response)
            setApiKeys(response.data.apiKeys)
        })
    }, []);


    function handleDeleteToken(event) {
        console.log("Delete apiKey ++")
        console.log(event.target.id)
        console.log("Delete apiKey ==")
        apiClient.delete("/api/token", {
            data: {
                "token": event.target.id
            }
        }).then(response => {
            console.log("Deleted api key")
            setApiKeys(response.data.apiKeys)
        })
    }

    function renderDeleteButton(apiKey) {
        let button_val = "Delete"
        return <Button
            id={apiKey.token}
            outline
            color="danger"
            onClick={handleDeleteToken}
        >
            {button_val}
        </Button>
    }

    function renderTable() {
        return apiKeys.map((apiKey, index) => (
            <tr key={apiKey.token}>
                <td>
                    <h3>{apiKey.name}</h3>
                </td>
                <td>
                    <h2><Badge className="badge-info" pill>
                        {apiKey.created_at}
                    </Badge></h2>
                </td>
                <td>
                    {renderDeleteButton(apiKey)}
                </td>
            </tr>
        ))
    }

    function getTable() {
        return (
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Delete</th>
                    <th scope="col"/>
                </tr>
                </thead>
                <tbody>
                {renderTable()}
                </tbody>
            </Table>
        )
    }

    function renderApiKeysTable() {
        const heading = "API Keys"
        return (
            <div className="col">
                <Card className="shadow">
                    <CardHeader className="border-0">
                        <h3 className="mb-0">{heading}</h3>
                    </CardHeader>
                    {getTable()}
                </Card>
            </div>
        )
    }

    function handleApiKeyCreation() {
        console.log("IM HERE!!!")
        console.log(apiKey)
        if (!apiKey) {
            console.log("NOT EXISTS")
            return
        }
        setApiKeyCreationInProgress(true)
        console.log(apiKey)
        apiClient.post("/api/token", {
            'name': apiKey,
        }).then(response => {
            console.log("API Key Created")
            console.log(response)
            setApiKeyCreationInProgress(false)
            setApiKeys(response.data.apiKeys)
            setApiKeyCreated(true)
            setCreatedToken(response.data.createdToken)
        })
    }

    function renderAwsSaveButton() {
        if (apiKeyCreationInProgress) {
            return <Spinner animation="border" role="status"> </Spinner>
        } else {
            return (
                <div>
                    <Button className="btn-icon btn-3" onClick={handleApiKeyCreation} color="primary"
                            type="button">
                        <span className="btn-inner--text">{apiKeyCreated ? "Created!" : "Create"}</span>
                    </Button>
                    {apiKeyCreated ? <i className="text-green fas fa-check"/> : ""}
                </div>
            )
        }
    }


    function ClipboardCopy({copyText}) {
        // This is the function we wrote earlier
        async function copyTextToClipboard(text) {
            if ('clipboard' in navigator) {
                return await navigator.clipboard.writeText(text);
            } else {
                return document.execCommand('copy', true, text);
            }
        }

        // onClick handler function for the copy button
        const handleCopyClick = () => {
            // Asynchronously call copyTextToClipboard
            copyTextToClipboard(copyText)
                .then(() => {
                    // If successful, update the isCopied state value
                    setIsCopied(true);
                    setTimeout(() => {
                        setIsCopied(false);
                    }, 1500);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        return (
            <div>
                <Row>
                    <Col lg="4">
                        <Input type="text" value={copyText} readOnly/>
                    </Col>
                    <Col>
                        <Button
                            onClick={handleCopyClick}
                            color="default"
                            outlinec
                        >
                            <i className="fas fa-copy"></i>
                            <span>{isCopied ? 'Copied!' : 'Copy'}</span>
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }


    function displayCreatedToken() {
        if (!createdToken) {
            return
        }
        return (
            <div className="mt-3">
                <DropdownItem divider/>
                <ClipboardCopy copyText={createdToken}/>
            </div>
        )
    }

    function renderCreateAPIKey() {
        return (
            <>
                <Row>
                    <Col lg="4">
                        <FormGroup>
                            {/*<Label for="Name"><i className="ni ni-badge mr-2"/>API Key Name</Label>*/}
                            <Input
                                className="form-control-alternative"
                                defaultValue=''
                                id="input-access-key"
                                placeholder="API Key Name"
                                type="text"
                                onChange={event => setApiKey(event.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        {renderAwsSaveButton()}
                    </Col>
                </Row>
                {displayCreatedToken()}
            </>
        );
    }


    function renderAPITab() {
        return (
            <>
                <APIHeader/>
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Create API Key</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup>
                                                        {renderCreateAPIKey()}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        {renderApiKeysTable()}
                    </Row>
                </Container>
            </>
        );
    }

    return (
        <>
            {renderAPITab()}
        </>
    );
}

export default API;
