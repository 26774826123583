/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState} from "react";
import { Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import {ProtectedRoute} from "../utils/protected.routes";
import {loginAndSetUserInfo} from "../utils/login";

const Admin = (props) => {
  // componentDidUpdate(e) {
  //   document.documentElement.scrollTop = 0;
  //   document.scrollingElement.scrollTop = 0;
  //   this.refs.mainContent.scrollTop = 0;
  // }

  const [userInfo, setUserInfo] = useState([]);

  useEffect(() => {
    // document.documentElement.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;
    loginAndSetUserInfo(setUserInfo);
    return () => {
    }
  },[]);

  function getRoutes(routes) {
    return routes.map((route, key) => {
      if (route.layout === "/admin") {
        const Component = route.component
        return (
          <ProtectedRoute
            exact
            path={route.layout + route.path}
            render={() => <Component userInfo={userInfo} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  }

  function getBrandText(path) {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  }

  function doesUserExists() {
    return Object.keys(userInfo).length !== 0
  }

  function renderSideBar() {
    return (
        <Sidebar
            {...props}
            userInfo={userInfo}
            routes={routes}
            logo={{
              innerLink: "/",
              imgSrc: require("assets/img/brand/cirun.png").default,
              imgAlt: "..."
            }}
        />
    )
  }

  function renderNavBar() {
    return (
        <AdminNavbar
            {...props}
            brandText={getBrandText(props.location.pathname)}
            userInfo={userInfo}
        />
    )
  }

  function redirectPath() {
    if (doesUserExists()) {
      return <Redirect from="*" to={{ pathname: '/admin/dashboard' }}/>
    } else {
      return <Redirect from="*" to={{ pathname: '/auth/login' }}/>
    }
  }

  function renderAdmin() {
    return (
        <>
          {doesUserExists() ? renderSideBar(): <></>}
          <div className="main-content">
          {/*<div className="main-content" ref="mainContent">*/}
            {doesUserExists() ? renderNavBar(): <></>}
            <Switch>
              {getRoutes(routes)}
              {redirectPath()}
            </Switch>
            <Container fluid>
              {doesUserExists() ? <AdminFooter/>: <></>}
            </Container>
          </div>
        </>
    )
  }


    return (
        <>
          {renderAdmin()}
        </>
    );
}

export default Admin;
