/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

const fontStyleBold = {
  "fontFamily": "Fira Sans",
  "fontWeight": 600
}

class Login extends React.Component {
  render() {
    return (
      <>
        <footer className="py-5 auth-footer">
          <Container>
            <Row className="align-items-center justify-content-xl-between">
              <Col xl="6">
                <div className="copyright text-center text-xl-left text-muted" style={fontStyleBold}>
                  © 2021-{new Date().getFullYear()}{" "}
                  <a
                    className="font-weight-bold ml-1"
                    href="https://cirun.io"
                    target="_blank"
                  >
                    Cirun.io, Aktech Labs
                  </a>
                </div>
              </Col>
              <Col xl="6">
                <Nav className="nav-footer justify-content-center justify-content-xl-end">
                  <NavItem>
                    <NavLink
                      href="https://aktechlabs.com/about/"
                      target="_blank"
                      style={fontStyleBold}
                    >
                      About Us
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                        href="https://cirun.instatus.com/"
                        target="_blank"
                        style={fontStyleBold}
                    >
                      System Status
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://aktechlabs.com/blog/"
                      target="_blank"
                      style={fontStyleBold}
                    >
                      Blog
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                        href="https://twitter.com/CirunHQ/"
                        target="_blank"
                        style={fontStyleBold}
                    >
                      Twitter
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                        href="https://docs.cirun.io/terms-of-service"
                        target="_blank"
                        style={fontStyleBold}
                    >
                      TOC
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                        href="https://docs.cirun.io/privacy-policy"
                        target="_blank"
                        style={fontStyleBold}
                    >
                      Privacy Policy
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default Login;
