import React from "react";
import {Button, Card, CardBody, CardText, CardTitle} from "reactstrap";
import {loginGitHub} from "../../utils/login";
import getBaseUrl from "../../views/utils";

export const PricingCard = ({title, description, price, plan, planStripeLink, imgSrc, userInfo}) => {

    function planLink() {
        if(userInfo.hasOwnProperty('name')) {
            const planUrl = `${getBaseUrl()}/api/checkout?plan=` + plan
            return plan ? planUrl : "/admin/dashboard/"
        }
    }

    const fontStyleBold = {
        "fontFamily": "Fira Sans",
        "fontWeight": 600
    }

    return (
        <Card className="shadow border-0" style={{height: "100%"}}>
            <CardBody style={{display: "flex", flexDirection: "column"}}>
                <CardTitle>
                    <h2 style={fontStyleBold}>{title}</h2>
                </CardTitle>
                <div
                    className='text display-2'
                    style={
                        {
                            "fontFamily": "Source Sans Pro",
                        }
                    }
                >
                    {price}
                </div> <div>
                per month
            </div>
                <CardText>
                        {description}
                </CardText>

                {planLink() ?
                    <Button
                        color="default"
                        size="lg"
                        style={{width: "100%", marginTop: "auto"}}
                        href={plan === "enterprise" ? "mailto:amit@cirun.io": planLink()}
                    >
                        {plan === "enterprise" ? "Contact" : "Subscribe"}
                    </Button> :

                    <Button
                        color="default"
                        size="lg"
                        onClick={loginGitHub}
                        style={{width: "100%", marginTop: "auto"}}
                    >
                        <div className="text-md">
                            {plan === "enterprise" ? "Contact" : "Subscribe"}
                        </div>

                    </Button>}
            </CardBody>
        </Card>
    )
}
