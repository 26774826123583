/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState} from "react";
import {Container, Row, Col} from "reactstrap";

import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

import Landing from "../components/Landing";
import {loginAndSetUserInfo} from "../utils/login";

const Auth = () => {

  const [userInfo, setUserInfo] = useState([]);

    useEffect(() => {

    document.body.classList.add("bg-dark");
        loginAndSetUserInfo(setUserInfo);
    return () => {
      document.body.classList.remove("bg-dark");

    }
  },[]);

    const fontStyle = {
        "fontFamily": "Fira Sans",
        "fontWeight": 400
    }

  function getHeadingTitle() {
    return (
        <div className="header-body text-center mb-3">
          <Row className="justify-content-center">
            <Col lg="12" md="12" xs="12">
              <h1
                  className="text-white display-1"
                  style={
                      {
                          "fontFamily": "Fira Sans",
                          // "fontFamily": "Space Grotesk",
                      }
                  }
              > GitHub Actions on your cloud </h1>
              <p

                  className="text-gray display-4 mt-1"
                  style={fontStyle}

              >
                Managed self-hosted runners at the fraction of cost, on <mark>your</mark> cloud
              </p>
            </Col>
          </Row>
        </div>
    )
  }

  return (
      <>
        <div className="main-content auth-page">
          <
            AuthNavbar
            userInfo={userInfo}
          />
          <div className="header py-7 py-lg-8">
            <Container>
              {getHeadingTitle()}
            </Container>
          </div>
          {/* Page content */}
          <Container className="mt--8 pb-5">
            {/*<Row className="justify-content-center">*/}
              <
                  Landing
                  userInfo={userInfo}
              />
            {/*</Row>*/}
          </Container>
        </div>
        <AuthFooter />
      </>
  );
}

export default Auth;
