/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {Link} from "react-router-dom";
// reactstrap components
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Row,
  UncontrolledCollapse,
  UncontrolledDropdown
} from "reactstrap";
import {loginGitHub, logout} from "../../utils/login";

const fontStyleBold = {
  "fontFamily": "Fira Sans",
  "fontWeight": 500
}

class AuthNavbar extends React.Component {

  // const fontStyleBold = {
  //   "fontFamily": "Fira Sans",
  //   "fontWeight": 600
  // }

  getLoginOrAvatar() {
    const avatar = (
        <UncontrolledDropdown nav>
          <DropdownToggle className="pr-0" nav>
            <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                          alt="User's Avatar"
                          src={this.props.userInfo.avatar_url}
                      />
                    </span>
            </Media>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem href="/admin/dashboard">
              <i className="fa fa-bolt"/>
              <span style={fontStyleBold}>Dashboard</span>
            </DropdownItem>
            <DropdownItem divider/>
            <DropdownItem
                onClick={logout}
            >
              <i className="fas fa-sign-out-alt"/>
              <span style={fontStyleBold}>Logout</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
    )

    const loginButton = (
        <NavItem>
          <NavLink
              className="nav-link-icon"
              onClick={loginGitHub}
          >
            <i className="fas fa-sign-in-alt"/>
            <span className="nav-link-inner--text" style={fontStyleBold}>Login</span>
          </NavLink>
        </NavItem>
    )
    return this.props.userInfo.hasOwnProperty('avatar_url') ? avatar : loginButton
  }

  render() {
    return (
      <>
        <Navbar
          className="navbar-top navbar-horizontal navbar-dark"
          expand="md"
        >
          <Container className="px-4">
            <NavbarBrand to="/" tag={Link}>
              <Link to="/">
                <img
                    alt="cirun logo"
                    src={require("assets/img/brand/cirun-logo-inverted.svg").default}
                    style={{
                      minHeight: "75px",
                    }}
                />
              </Link>
            </NavbarBrand>
            <button className="navbar-toggler" id="navbar-collapse-main">
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
              <div className="navbar-collapse-header d-md-none">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/">
                      <img
                          alt="cirun logo"
                          src={require("assets/img/brand/cirun-logo.svg").default}
                          style={{
                            minHeight: "100px",
                          }}
                      />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button
                      className="navbar-toggler"
                      id="navbar-collapse-main"
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>

              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink
                      className="nav-link-icon"
                      href="https://docs.cirun.io">
                    <i className="fas fa-copy"/>
                      <span className="nav-link-inner--text" style={fontStyleBold}>Docs</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                      className="nav-link-icon"
                      href="https://aktechlabs.com/about/"
                      target="_blank"
                  >
                    <i className="fas fa-user-alt"/>
                    <span className="nav-link-inner--text" style={fontStyleBold}>About</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                      className="nav-link-icon"
                      href="#pricing"
                  >
                    <i className="fas fa-tags"/>
                    <span className="nav-link-inner--text" style={fontStyleBold}>Pricing</span>
                  </NavLink>
                </NavItem>
                {this.getLoginOrAvatar()}
              </Nav>

            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AuthNavbar;
