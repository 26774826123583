import axios from "axios";
import getCookie from "./utils/cookie_utils";
import getBaseUrl from "./views/utils";


const apiClient = axios.create({
    baseURL: getBaseUrl(),
    withCredentials: true,
    headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-TOKEN': getCookie('csrf_access_token'),
    },
});

export default apiClient;
