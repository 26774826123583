/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardImg,
    Col, Container, Row, DropdownItem
} from "reactstrap";
import { PricingCard } from "components/Pricing/PricingCard";
import useWindowDimensions from "../utils/screen";
import {loginGitHub} from "../utils/login";


const Landing = ({ userInfo }) => {
    const { height, width } = useWindowDimensions();
    const videoWidth = width >= 750 ? width / 2 : width / 1.2;
    const videoHeight = 9 * videoWidth / 16;
    const videoUrl = "https://www.youtube.com/embed/lDx0YVTn6yg";

    const osPricingDesc = [
        "✔ Unlimited public repositories",
        "✔ Unlimited runners",
        "✔ Arm based runners",
        "✔ GPU runners",
        "✔ All supported cloud providers",
    ]

    const fontStyle = {
        "fontFamily": "Fira Sans",
        "fontWeight": 400
    }
    const fontStyleBold = {
        "fontFamily": "Fira Sans",
        "fontWeight": 600
    }
    function pricingBullet(text) {
        return (
            <p
                className="text-black-50 mb-0"
                style={fontStyle}
            >
                {text}
            </p>
        )
    }

    function pricingDescOpenSource() {
        return (
            <>
                {pricingBullet("✔ Install Cirun on Unlimited public repositories")}
                {pricingBullet("✔ Unlimited runners")}
                {pricingBullet("✔ Arm based runners")}
                {pricingBullet("✔ GPU runners")}
                {pricingBullet("✔ All supported cloud providers")}
            </>
        )
    }

    function pricingDescStartup() {
        return (
            <>
                {pricingBullet("Everything in Open Source +")}
                {pricingBullet("✔ Install Cirun on 3 private repositories")}
                {pricingBullet("✔ Support via Slack/Email")}
            </>
        )
    }

    function pricingDescBusiness() {
        return (
            <>
                {pricingBullet("Everything in Open Source +")}
                {pricingBullet("✔ Install Cirun on 10 private repositories")}
                {pricingBullet("✔ Support by Slack/Email")}
            </>
        )
    }

    function pricingDescEnterprise() {
        return (
            <>
                {pricingBullet("Everything in Open Source +")}
                {pricingBullet("✔ Install Cirun on unlimited private repositories")}
                {pricingBullet("✔ Premium support by Slack/Email/Phone")}
                {/*<i className="fa-solid fa-phone"></i>*/}
                <i className="fa fa-envelope" aria-hidden="true"></i>
                {pricingBullet("Contact us for custom requirements")}
            </>
        )
    }

    function getPricing() {

        function createPlanLink(link) {
            return `${link}?prefilled_email=${userInfo.email}`
        }

        return (
            <>
                <Row className="mt-5 justify-content-md-center" id="pricing">
                    <Col>
                        <h1 className="text-white text-center display-2" style={fontStyleBold}>Pricing</h1>
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-4" lg="3" md="3">
                        <PricingCard
                            title="Open Source"
                            description={pricingDescOpenSource()}
                            price="$0"
                            imgSrc={require("assets/img/pricing/pricing-opensource.jpeg").default}
                            userInfo={userInfo}
                        />
                    </Col>
                    <Col className="mt-4" lg="3" md="3">
                        <PricingCard
                            title="Startup"
                            description={pricingDescStartup()}
                            price="$29"
                            plan="startup"
                            planStripeLink={createPlanLink("https://buy.stripe.com/14k17Id3y5Xs0WQ8ww")}
                            imgSrc={require("assets/img/pricing/pricing-startup.jpg").default}
                            userInfo={userInfo}
                        />
                    </Col>
                    <Col className="mt-4" lg="3" md="3">
                        <PricingCard
                            title="Business"
                            description={pricingDescBusiness()}
                            price="$79"
                            plan="business"
                            planStripeLink={createPlanLink("https://buy.stripe.com/28o5nYbZu99E34YfZ0")}
                            imgSrc={require("assets/img/pricing/pricing-startup.jpg").default}
                            userInfo={userInfo}
                        />
                    </Col>
                    <Col className="mt-4" lg="3" md="3">
                        <PricingCard
                            title="Enterprise"
                            description={pricingDescEnterprise()}
                            // price="$99"
                            price="Custom"
                            plan="enterprise"
                            // planStripeLink={createPlanLink("https://buy.stripe.com/3csaIibZu2LggVO3cd")}
                            planStripeLink=""
                            imgSrc={require("assets/img/pricing/pricing-enterprise.jpg").default}
                            userInfo={userInfo}
                        />
                    </Col>
                </Row>
            </>
        )
    }

    function getDemo() {
        return (
            <Row className="align-items-center justify-content-md-center mb-2 mt-5">
                <div className="header-body text-center mb-3">
                    <Col>
                        <p className="text-white text-center display-3" style={fontStyleBold}>
                            <i className="fas fa-laptop-code mr-2" />Demo
                        </p>
                    </Col>
                    <Col className="order-md-1" md="12">
                        <div className="pr-md-5">
                            <p className="text-gray lead" style={fontStyle}>
                                A quick demo of "How to use Cirun?"
                            </p>
                        </div>
                    </Col>
                    <Col lg="6" md="5">
                        <div className="btn-wrapper text-center">
                            <iframe width={videoWidth} height={videoHeight} src={videoUrl} frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen>Cirun Demo</iframe>
                        </div>
                    </Col>
                </div>
            </Row>
        )
    }

    function getCTAButton() {
        const getStartedButton = (
            <Button
                className="btn-lg text-xl box"
                color="default"
                onClick={loginGitHub}
            >
                <span className="btn-inner--icon"><i className="fab fa-github" /></span>
                <span className="btn-inner--text" style={fontStyleBold}> Get Started</span>
            </Button>
        )
        const dashboardButton = (
            <Button
                className="btn-lg text-xl box"
                color="default"
                href="/admin/dashboard"
            >
                <span className="btn-inner--icon"><i className="fa fa-bolt"/></span>
                <span className="btn-inner--text" style={fontStyleBold}> Dashboard </span>
            </Button>
        )
        return userInfo.hasOwnProperty('name') ? dashboardButton : getStartedButton
    }

    function getStarted() {
        return (
            <Row className="align-items-center justify-content-md-center mb-2 mt-5">
                <div className="header-body text-center mb-3">
                    <Col>
                        <p className="text-white text-center display-3" style={fontStyleBold}>
                            <i className="fas fa-rocket mr-2" />Get Started
                        </p>
                    </Col>
                    <Col className="order-md-1" md="12">
                        <div className="pr-md-5">
                            <p className="text-gray lead" style={fontStyle}>
                                Checkout our documentation on how to get started on using Cirun with GitHub Actions!
                            </p>
                        </div>
                    </Col>
                    <Col className="mt-1" lg="12" md="6" xs="6">
                        <Button
                            className="btn-lg box"
                            color="primary"
                            outline
                            href="https://docs.cirun.io"
                        >
                            <span className="btn-inner--text">Checkout Documentation</span>
                        </Button>
                    </Col>
                </div>
            </Row>
        )
    }

    function wallOfLove() {
        return (
            <div className="">
                <Row className="align-items-center justify-content-md-center">
                    <div className="header-body text-center mb-3">
                        <Col md={12}>
                            <p className="text-white text-center display-3" style={fontStyleBold}>
                                <i className="fab fa-twitter mr-2" />Testimonials
                            </p>
                        </Col>
                    </div>
                </Row>
                {/*<Row className="mb-2 mt-5">*/}
                            <Row
                                className="senja-embed"
                                data-id="0ce1b12f-84b4-4c8e-a3a5-1f367aebc597"
                                data-lazyload="false"
                                style={{display: "block;"}}
                            ></Row>
                {/*</Row>*/}
            </div>
        )

    }

    function getCloudLogos() {
        return <Row className="align-items-center opacity-7 justify-content-md-center mb-5 mt-5">
            <Col className="mt-1 " lg="1" md="2" xs="2">
                <Card className="border-0 p-1">
                    <CardImg
                        alt="..."
                        src={require("../assets/img/theme/aws.png").default}
                        top
                    />
                </Card>
            </Col>
            <Col className="mt-1" lg="1" md="2" xs="2">
                <Card className="shadow border-0 p-1">
                    <CardImg
                        alt="..."
                        src={require("../assets/img/theme/gcp.png").default}
                        top
                    />
                </Card>
            </Col>
            <Col className="mt-1" lg="1" md="2" xs="2">
                <Card className="p-1">
                    <CardImg
                        alt="..."
                        src={require("../assets/img/theme/do.png").default}
                        top
                    />
                </Card>
            </Col>
            <Col className="mt-1" lg="1" md="2" xs="2">
                <Card className="p-1">
                    <CardImg
                        alt="..."
                        src={require("../assets/img/theme/azure.png").default}
                        top
                    />
                </Card>
            </Col>
            <Col className="mt-1" lg="1" md="2" xs="2">
                <Card className="p-1">
                    <CardImg
                        alt="..."
                        src={require("../assets/img/theme/openstack.jpg").default}
                        top
                    />
                </Card>
            </Col>
            <Col className="mt-1" lg="1" md="2" xs="2">
                <Card className="p-1">
                    <CardImg
                        alt="oracle-cloud logo"
                        src={require("../assets/img/theme/oracle.png").default}
                        top
                    />
                </Card>
            </Col>
        </Row>
    }

    function getLoginAndDocLinks() {
        return (
            <Row>
                <Col className="mt-1" lg="9" md="9" xs="9">
                    {getCTAButton()}
                </Col>
                <Col className="mt-1" lg="3" md="3" xs="3">
                    <Button
                        className="btn-icon text-xl"
                        color="danger"
                        type="button"
                        outline
                        href="https://slack.cirun.io/"
                    >
                        <span className="btn-inner--icon"><i className="fab fa-slack" /></span>
                    </Button>
                </Col>
            </Row>

        )
    }

    function getGithubChecks() {
        return (
            <Row className="row-grid align-items-center">
                <Col className="order-md-2" lg="7" md="7">
                    <img
                        alt="..."
                        className="img-fluid floating"
                        src={require("assets/img/github/gh_checks.png").default}
                    />
                </Col>
                <Col className="order-md-1" md="5">
                    <div className="pr-md-5">
                        <h1 className="text-white display-3" style={fontStyleBold}>
                            <i className="fab fa-github mr-2" />
                            Native
                        </h1>
                        <p className="text-gray lead" style={fontStyle}>
                            It's native to Github, easily see checks and logs in the GitHub's interface.
                        </p>
                    </div>
                </Col>
            </Row>
        )
    }

    function fastCI() {
        return (
            <Row className="row-grid align-items-center">
                <Col className="order-md-1" lg="7" md="7">
                    <img
                        alt="..."
                        className="img-fluid floating"
                        src={require("assets/img/github/fast-ci.png").default}
                    />
                </Col>
                <Col className="order-md-2" md="5">
                    <div className="pr-md-5">
                        <h1 className="text-white display-3" style={fontStyleBold}>
                            <i className="fas fa-tachometer-alt mr-2"/>
                            {/*<i className="fab fa-solid fa-gauge-simple-high"></i>*/}
                            Fast
                        </h1>
                        <p className="text-gray lead" style={fontStyle}>
                            Super Fast CI with more performant machines from  your favourite cloud provider.
                        </p>
                    </div>
                </Col>
            </Row>
        )
    }

    function easyToConfig() {
        return (
            <Row className="row-grid">
                <Col className="" md="5">
                    <div className="pr-md-5">
                        <h1 className="text-white display-3" style={fontStyleBold}>
                            <i className="far fa-file-alt mr-2" />
                            Easy to Configure
                        </h1>
                        <p className="text-gray lead" style={fontStyle}>
                            Runners can be configured with just one-line change and advanced configuration can easily be done with a simple yaml file.
                        </p>
                    </div>
                </Col>
                <Col className="" lg="7" md="7">
                    <img
                        alt="..."
                        className="img-fluid floating"
                        src={require("assets/img/github/cirun-yaml.png").default}
                    />
                </Col>
            </Row>
        )
    }

    function onDemandRunners() {
        return (
            <Row className="row-grid">
                <Col className="" lg="7" md="7">
                    <img
                        alt="..."
                        className="img-fluid floating"
                        src={require("assets/img/cirun-runners.png").default}
                    />
                </Col>
                <Col className="" md="5">
                    <div className="pr-md-5">
                        <h1 className="text-white display-3" style={fontStyleBold}>
                            <i className="fas fa-funnel-dollar mr-2" />
                            On-Demand Runners
                        </h1>
                        <p className="text-gray lead" style={fontStyle}>
                            Only pay for what you use, to your cloud provider. Runners are terminated immediately after job completion.
                        </p>
                    </div>
                </Col>
            </Row>
        )
    }

    function unlimitedCustomization() {
        return (
            <Row className="row-grid">
                <Col className="" md="5">
                    <div className="pr-md-5">
                        <h1 className="text-white display-3" style={fontStyleBold}>
                            <i className="fas fa-tools mr-2" />
                            Unlimited Customization
                        </h1>
                        <p className="text-gray lead" style={fontStyle}>
                            Use a custom image or pre-existing images from your cloud provider.
                        </p>
                        <p className="text-gray" style={fontStyle}>
                            (Example Image from AWS Marketplace)
                        </p>
                    </div>
                </Col>
                <Col className="" lg="7" md="7">
                    <img
                        alt="..."
                        className="img-fluid floating"
                        src={require("assets/img/nvidia-aws-ami.png").default}
                    />
                </Col>
            </Row>
        )
    }

    function otherFeatures() {
        return (
            <Row className="row-grid">
                <Col className="" md="4">
                    <div className="pr-md-4">
                        <h1 className="text-white" style={fontStyleBold}>
                            <i className="fas fa-users mr-2" />
                            Free for Open Source
                        </h1>
                        <p className="text-gray lead" style={fontStyle}>
                            We ♥️ Open Source. Cirun.io is free for Open source projects.
                        </p>
                    </div>
                </Col>
                <Col className="" md="4">
                    <div className="pr-md-4">
                        <h1 className="text-white" style={fontStyleBold}>
                            <i className="fas fa-server mr-2" />
                            GPU Support
                        </h1>
                        <p className="text-gray lead" style={fontStyle}>
                            We also support GPU enabled runners on your cloud provider.
                        </p>
                    </div>
                </Col>
                <Col className="" md="4">
                    <div className="pr-md-4">
                        <h1 className="text-white" style={fontStyleBold}>
                            <i className="fas fa-power-off mr-2" />
                            Preemptible runners
                        </h1>
                        <p className="text-gray lead" style={fontStyle}>
                            We support low cost preemptible instances on GCP and AWS.
                        </p>
                    </div>
                </Col>
            </Row>
        )
    }

    function projectsUsingCirun() {
        return (
            <Col>
                <Row className="align-items-center justify-content-md-center mt-8">
                    <div className="header-body text-center mb-3">
                        <Col md={12}>
                            <p className="text-white text-center display-3" style={fontStyleBold}>
                                {/*<i className="fas fa-certificate mr-2" />*/}
                                Trusted by great Teams and Projects
                            </p>
                        </Col>
                    </div>
                </Row>
                <Row>
                    <DropdownItem divider />
                </Row>
                <Row className="align-items-center justify-content-md-center mb-5 ">
                    <Col className="mt-1 opacity-6" lg="3" md="2" xs="2">
                        <Card className="border-0 p-2">
                            <CardImg
                                alt="..."
                                src={require("../assets/img/logos-using-cirun/snarkify.svg").default}
                                top
                            />
                        </Card>
                    </Col>
                    <Col className="mt-1 opacity-6" lg="3" md="2" xs="2">
                        <Card className="shadow border-0 p-1">
                            <CardImg
                                alt="..."
                                src={require("../assets/img/logos-using-cirun/kitware.svg").default}
                                top
                            />
                        </Card>
                    </Col>
                    <Col className="mt-1 opacity-6" lg="2" md="2" xs="2">
                        <Card className="p-1">
                            <CardImg
                                alt="..."
                                src={require("../assets/img/logos-using-cirun/quansight.png").default}
                                top
                            />
                        </Card>
                    </Col>
                    <Col className="mt-1 opacity-6" lg="2" md="2" xs="2">
                        <Card className="p-3">
                            <CardImg
                                alt="..."
                                src={require("../assets/img/logos-using-cirun/sgkit.png").default}
                                top
                            />
                        </Card>
                    </Col>
                    <Col className="mt-1 opacity-6" lg="2" md="2" xs="2">
                        <Card className="p-2">
                            <CardImg
                                alt="..."
                                src={require("../assets/img/logos-using-cirun/coiled.jpeg").default}
                                top
                            />
                        </Card>
                    </Col>
                    <Col className="mt-1 opacity-6" lg="3" md="2" xs="2">
                        <Card className="p-2">
                            <CardImg
                                alt="..."
                                src={require("../assets/img/logos-using-cirun/helfrich.png").default}
                                top
                            />
                        </Card>
                    </Col>
                    <Col className="mt-1 opacity-6" lg="2" md="2" xs="2">
                        <Card className="p-2">
                            <CardImg
                                alt="..."
                                src={require("../assets/img/logos-using-cirun/conda-forge.png").default}
                                top
                            />
                        </Card>
                    </Col>
                    <Col className="mt-1 opacity-6" lg="3" md="2" xs="2">
                        <Card className="p-2">
                            <CardImg
                                alt="..."
                                src={require("../assets/img/logos-using-cirun/etched.png").default}
                                top
                            />
                        </Card>
                    </Col>
                    <Col className="mt-1 opacity-6" lg="3" md="2" xs="2">
                        <Card className="p-2">
                            <CardImg
                                alt="..."
                                src={require("../assets/img/logos-using-cirun/nebari.png").default}
                                top
                            />
                        </Card>
                    </Col>
                </Row>
            </Col>
        )
    }


    return (
        <>
            <section>

                <Row className="align-items-center justify-content-md-center">
                    {getLoginAndDocLinks()}
                </Row>
            </section>

            <section className="mt-4">
                <Container>
                    {getCloudLogos()}
                    <Row className="mt-5 justify-content-md-center">
                        <img
                            alt="Cirun one line change example"
                            className="img-fluid"
                            style={{width: "60%"}}
                            src={require("assets/img/cirun-one-line.gif").default}
                        />
                    </Row>
                </Container>
            </section>

            <section className="section section-shaped mt-6">
                {getGithubChecks()}
            </section>

            <section className="section section-shaped mt-6">
                {fastCI()}
            </section>
            <section className="mt-8">
                {easyToConfig()}
            </section>

            <section className="mt-8">
                {onDemandRunners()}
            </section>

            <section className="mt-8">
                {unlimitedCustomization()}
            </section>

            <section className="mt-8">
                {otherFeatures()}
            </section>

            <section className="mt-2">
                {projectsUsingCirun()}
            </section>

            <section className="mt-8">
                {getPricing()}
            </section>

            <section className="mt-8">
                {getDemo()}
            </section>

            <section className="mt-4">
                {getStarted()}
            </section>

            <section className="mt-4">
                {wallOfLove()}
            </section>
        </>
    )
}

export default Landing;
