/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";

const Profile = ({ userInfo }) => (
  <>
    <UserHeader userInfo={userInfo} />
    {/* Page content */}
    <Container className="mt--7" fluid>
      <Row>
        <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
          <Card className="card-profile shadow">
            <Row className="justify-content-center">
              <Col className="order-lg-2" lg="3">
                <div className="card-profile-image">
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img
                      alt="..."
                      className="rounded-circle"
                      src={userInfo.avatar_url}
                    />
                  </a>
                </div>
              </Col>
            </Row>
            <CardBody className="pt-0 pt-md-8">
              <div className="text-center">
                <h3>
                  {userInfo.name}
                </h3>
                <div className="h5 font-weight-300">
                  <i className="ni location_pin mr-2" />
                  {userInfo.location}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col className="order-xl-1" xl="8">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Account Information</h3>
                </Col>
                {/*TODO: Create profile endpoint to update it*/}
                {/*<Col className="text-right" xs="4">*/}
                {/*  <Button*/}
                {/*    color="primary"*/}
                {/*    href="/api/profile"*/}
                {/*    onClick={e => e.preventDefault()}*/}
                {/*    size="sm"*/}
                {/*  >*/}
                {/*    Save Changes*/}
                {/*  </Button>*/}
                {/*</Col>*/}
              </Row>
            </CardHeader>
            <CardBody>
              <Form>
                <h6 className="heading-small text-muted mb-4">
                  User information
                </h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                        >
                          Name
                        </label>
                        <Input
                            className="form-control-alternative"
                            defaultValue={userInfo.name}
                            id="input-first-name"
                            placeholder="First name"
                            type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-email"
                        >
                          Email address
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-email"
                          placeholder={userInfo.email}
                          type="email"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </>
)

export default Profile;
