/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useEffect, useState} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col, Spinner, Label, DropdownItem
} from "reactstrap";
// core components
import CloudHeader from "components/Headers/CloudHeader.js";
import apiClient from "../http_utils";
import getBaseUrl from "./utils";

const defaultPrivateKey = "-----BEGIN PRIVATE KEY-----\n" +
    "MIIEvQIBADANBgkqaaaaaa0BAQEFAASCBKcwggSjAgEAAoIBAQCyGVAPaBbRG5Yz\n" +
    "oWOfAjJf2R57gyVaXl00qzaaaaaao3z8DJpAMICI5AlqBcyDe1DEsLbyJyOHS4Ve\n" +
    "fvbmoAgNY/PWpJ2QUcusnw9n8JK4SB7w4FaxQxjq1oKSqJW2VTvP+EjViUd+rAwL\n" +
    "By5fnpAcqawpmmTUN5k+xNmkh/PcFfXnAvv5ipweAS6AzrJkodOdNXlRr6eVKvu0\n" +
    "................................................................\n" +
    "67W9r5n78Z919ptsqtxRTcs=\n" +
    "-----END PRIVATE KEY-----"


const defaultOracleConfig = "user=ocid1.user.oc1..aaaaaaaadsdsd...\n" +
    "compartment_id=ocid1.user.oc1..aaaaaaaadsddsdsd...\n" +
    "fingerprint=75:4c:99:1f:3d:1b:a5:ea:f0:cb:7b:01:5c:86:a2:84\n" +
    "tenancy=ocid1.tenancy.oc1..aaaaaaadsdsd...\n" +
    "region=uk-london-1"


const Cloud = ({ userInfo }) => {
    const [doToken, setDoToken] = useState(null);

    const [awsAccessKey, setAwsAccessKey] = useState(null);
    const [gcpServiceAccountJson, setGcpServiceAccountJson] = useState(null);
    const [awsAccessSecret, setAwsAccessSecret] = useState(null);
    const [awsSaveInProgress, setAwsSaveInProgress] = useState(false);

    const [gcpSaveInProgress, setGcpSaveInProgress] = useState(false);
    const [awsSaved, setAwsSaved] = useState(false);
    const [gcpSaved, setGcpSaved] = useState(false);
    const [user, setUser] = useState(userInfo);

    const [azureSubscriptionId, setAzureSubscriptionId] = useState(null)
    const [azureTenantId, setAzureTenantId] = useState(null)
    const [azureClientId, setAzureClientId] = useState(null)
    const [azureClientSecret, setAzureClientSecret] = useState(null)
    const [azureSaveInProgress, setAzureSaveInProgress] = useState(false);
    const [azureSaved, setAzureSaved] = useState(false);

    const [openstackUsername, setOpenstackUsername] = useState(null);
    const [openstackPassword, setOpenstackPassword] = useState(null);
    const [openstackAuthURL, setOpenstackAuthURL] = useState(null);
    const [openstackDomain, setOpenstackDomain] = useState(null);
    const [openstackNetwork, setOpenstackNetwork] = useState(null);
    const [openstackProjectId, setOpenstackProjectId] = useState(null);
    const [openstackSaveInProgress, setOpenstackSaveInProgress] = useState(false);
    const [openstackSaved, setOpenstackSaved] = useState(false);

    const [oracleConfig, setOracleConfig] = useState(null);
    const [oraclePrivateKey, setOraclePrivateKey] = useState(null);
    const [oracleSaveInProgress, setOracleSaveInProgress] = useState(false);
    const [oracleSaved, setOracleSaved] = useState(false);
    useEffect(() => {
        setDoToken(user.digitalocean)
    },[]);

    function getDoDetails() {
        if (doToken && doToken.hasOwnProperty('created_at')) {
            return (
                <div>
                    <p className="text-sm mb-0">DigitalOcean connected at: {doToken.created_at}</p>
                    <p className="text-sm">DigitalOcean expires at: {doToken.expires_at}</p>
                </div>

            )
        }
    }

    function renderConnectDO() {
        const doConnect = `${getBaseUrl()}/api/do-login`;
        return (
            <>
                <Row>
                    <div className="icon rounded-circle mr-4 icon-lg">
                        <i className="fab fa-digital-ocean"/>
                    </div>

                    <a href={doConnect} target="_blank">
                        <Button className={"btn-icon btn-3"} color="default" outline type="button">
                            <span className="btn-inner--icon"><i className="fab fa-digital-ocean"/></span>
                            <span className="btn-inner--text">Connect DigitalOcean</span>
                        </Button>
                    </a>

                    {getDoDetails()}

                    <p className="text-sm text-muted">
                        Connect DigitalOcean to be able to create self-hosted runners on your DigitalOcean
                        account.
                    </p>

                </Row>
            </>
        );
    }

    function handleSaveAWSKey() {
        if (!awsAccessKey || !awsAccessSecret) {
            return
        }
        setAwsSaveInProgress(true)
        console.log(awsAccessKey)
        console.log(awsAccessSecret)
        apiClient.post("/api/aws-connect", {
            'accessKey': awsAccessKey,
            'secretKey': awsAccessSecret
        }).then(response => {
            console.log("AWS Saved")
            console.log(response)
            setAwsSaveInProgress(false)
            setAwsSaved(true)
        })
    }

    function handleSaveGCPKey() {
        if (!gcpServiceAccountJson) {
            return
        }
        setGcpSaveInProgress(true)
        console.log(gcpServiceAccountJson)
        apiClient.post("/api/gcp-connect", {
            'serviceAccountJson': gcpServiceAccountJson,
        }).then(response => {
            console.log("GCP Saved")
            console.log(response)
            setGcpSaveInProgress(false)
            setGcpSaved(true)
        })
    }

    function handleSaveOracleKey() {
        if (!oracleConfig || !oraclePrivateKey) {
            return
        }
        setOracleSaveInProgress(true)
        apiClient.post("/api/oracle-connect", {
            'config': oracleConfig,
            'privateKey': oraclePrivateKey,
        }).then(response => {
            console.log("Oracle Saved")
            console.log(response)
            setOracleSaveInProgress(false)
            setOracleSaved(true)
        })
    }


    function handleSaveAzureKey() {
        if (!azureSubscriptionId || !azureTenantId || !azureClientId || !azureClientSecret) {
            return
        }
        setAzureSaveInProgress(true)
        console.log(azureSubscriptionId)
        console.log(azureTenantId)
        console.log(azureClientId)
        console.log(azureClientSecret)
        apiClient.post("/api/azure-connect", {
            'subscriptionId': azureSubscriptionId,
            'tenantId': azureTenantId,
            'clientId': azureClientId,
            'clientSecret': azureClientSecret
        }).then(response => {
            console.log("Azure Saved")
            console.log(response)
            setAzureSaveInProgress(false)
            setAzureSaved(true)
        })
    }

    function handleSaveOpenstackKey() {
        if (!openstackUsername || !openstackPassword || !openstackAuthURL || !openstackProjectId) {
            return
        }
        setOpenstackSaveInProgress(true)
        console.log(openstackUsername)
        console.log(openstackPassword)
        console.log(openstackAuthURL)
        console.log(openstackProjectId)
        console.log("openstackDomain " + openstackDomain)
        console.log("openstackNetwork " + openstackNetwork)
        apiClient.post("/api/openstack-connect", {
            'username': openstackUsername,
            'password': openstackPassword,
            'authURL': openstackAuthURL,
            'projectId': openstackProjectId,
            'domainId': openstackDomain,
            'network': openstackNetwork
        }).then(response => {
            console.log("Openstack Saved")
            console.log(response)
            setOpenstackSaveInProgress(false)
            setOpenstackSaved(true)
        })
    }
    
    function renderAwsSaveButton() {
        if (awsSaveInProgress) {
            return <Spinner animation="border" role="status"> </Spinner>
        } else {
            const button_cls = awsAccessSecret && awsAccessKey ? "" : "disabled"
            return (
                <div>
                    <Button className={"btn-icon btn-3 " + button_cls} onClick={handleSaveAWSKey} color="primary"
                            type="button">
                        <span className="btn-inner--icon"><i className="fab fa-aws"/></span>
                        <span className="btn-inner--text">{awsSaved ? "Saved!": "Save"}</span>
                    </Button>
                    {awsSaved ? <i className="text-green fas fa-check"/>: ""}
                </div>
            )
        }
    }

    function renderAzureSaveButton() {
        if (azureSaveInProgress) {
            return <Spinner animation="border" role="status"> </Spinner>
        } else {
            const button_cls = azureSubscriptionId && azureTenantId && azureClientId && azureClientSecret ? "" : "disabled"
            return (
                <div>
                    <Button className={"btn-icon btn-3 " + button_cls} onClick={handleSaveAzureKey} color="primary"
                            type="button">
                        <span className="btn-inner--icon"><i className="fab fa-windows"/></span>
                        <span className="btn-inner--text">{azureSaved ? "Saved!": "Save"}</span>
                    </Button>
                    {azureSaved ? <i className="text-green fas fa-check"/>: ""}
                </div>
            )
        }
    }

    function renderOpenstackSaveButton() {
        if (openstackSaveInProgress) {
            return <Spinner animation="border" role="status"> </Spinner>
        } else {
            const button_cls = openstackUsername && openstackPassword && openstackAuthURL && openstackProjectId && openstackDomain  && openstackNetwork ? "" : "disabled"
            return (
                <div>
                    <Button className={"btn-icon btn-3 " + button_cls} onClick={handleSaveOpenstackKey} color="primary"
                            type="button">
                        <span className="btn-inner--icon"><i className="fab fas fa-server"/></span>
                        <span className="btn-inner--text">{openstackSaved ? "Saved!": "Save"}</span>
                    </Button>
                    {openstackSaved ? <i className="text-green fas fa-check"/>: ""}
                </div>
            )
        }
    }

    function renderGcpSaveButton() {
        if (gcpSaveInProgress) {
            return <Spinner animation="border" role="status"> </Spinner>
        } else {
            const button_cls = gcpServiceAccountJson ? "" : "disabled"
            return (
                <div>
                    <Button className={"btn-icon btn-3 " + button_cls} onClick={handleSaveGCPKey} color="primary"
                            type="button">
                        <span className="btn-inner--icon"><i className="fab fa-google"/></span>
                        <span className="btn-inner--text">{gcpSaved ? "Saved!": "Save"}</span>
                    </Button>
                    {gcpSaved ? <i className="text-green fas fa-check"/>: ""}
                </div>
            )
        }
    }

    function renderOracleSaveButton() {
        if (oracleSaveInProgress) {
            return <Spinner animation="border" role="status"> </Spinner>
        } else {
            const button_cls = oracleConfig && oraclePrivateKey ? "" : "disabled"
            return (
                <div>
                    <Button className={"btn-icon btn-3 " + button_cls} onClick={handleSaveOracleKey} color="primary"
                            type="button">
                        <span className="btn-inner--icon"><i className="fa fa-cloud"/></span>
                        <span className="btn-inner--text">{oracleSaved ? "Saved!": "Save"}</span>
                    </Button>
                    {oracleSaved ? <i className="text-green fas fa-check"/>: ""}
                </div>
            )
        }
    }



    function renderConnectAws() {
        return (
            <>
                <Row>
                    <div className="icon rounded-circle mr-4 icon-lg">
                        <i className="fab fa-aws"/>
                    </div>
                    <p className="text-sm text-muted">
                        Connect AWS to be able to create self-hosted runners on your AWS
                        account. Create an IAM user with Permission AmazonEC2FullAccess and generate
                        Access key ID and Access Secret Key and save them here.
                    </p>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="accessKey"><i className="ni ni-badge mr-2"/>Access Key</Label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={userInfo.aws_key}
                                id="input-access-key"
                                placeholder="aws access key"
                                type="text"
                                onChange={event => setAwsAccessKey(event.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="secretKey"><i className="ni ni-badge mr-2"/>Secret Key</Label>
                            <Input
                                className="form-control-alternative"
                                defaultValue=""
                                id="input-secret-key"
                                placeholder="aws secret key"
                                type="password"
                                onChange={event => setAwsAccessSecret(event.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                    <Row>
                        {renderAwsSaveButton()}
                </Row>


            </>
        );
    }

    function renderConnectAzure() {
        return (
            <>
                <Row>
                    <div className="icon rounded-circle mr-4 icon-lg">
                        <i className="fab fa-windows"/>
                    </div>
                    <p className="text-sm text-muted">
                        Connect Azure to be able to create self-hosted runners on your Azure
                        account. Generate clientId and clientSecret and save them here, for more information refer to Cirun documentation.
                    </p>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="subscriptionId"><i className="ni ni-badge mr-2"/>Azure Subscription ID</Label>
                            <Input
                                className="form-control-alternative"
                                defaultValue=""
                                id="input-subscription-id"
                                placeholder="azure subscription id"
                                type="text"
                                onChange={event => setAzureSubscriptionId(event.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="tenantId"><i className="ni ni-badge mr-2"/>Azure Tenant ID</Label>
                            <Input
                                className="form-control-alternative"
                                defaultValue=""
                                id="input-tenant-id"
                                placeholder="azure tenant id"
                                type="password"
                                onChange={event => setAzureTenantId(event.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="clientId"><i className="ni ni-badge mr-2"/>Azure Client ID</Label>
                            <Input
                                className="form-control-alternative"
                                defaultValue=""
                                id="input-client-id"
                                placeholder="azure client id"
                                type="text"
                                onChange={event => setAzureClientId(event.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="clientId"><i className="ni ni-badge mr-2"/>Azure Client Secret</Label>
                            <Input
                                className="form-control-alternative"
                                defaultValue=""
                                id="input-client-secret"
                                placeholder="azure client secret"
                                type="password"
                                onChange={event => setAzureClientSecret(event.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                    <Row>
                        {renderAzureSaveButton()}
                    </Row>
            </>
        );
    }

    function renderConnectOpenstack() {
        return (
            <>
                <Row>
                    <div className="icon rounded-circle mr-4 icon-lg">
                        <i className="fab fas fa-server"/>
                    </div>
                    <p className="text-sm text-muted">
                        Connect Openstack to be able to create self-hosted runners on your Openstack
                        server, for more information refer to Cirun documentation.
                    </p>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="userName"><i className="ni ni-badge mr-2"/>Username</Label>
                            <Input
                                className="form-control-alternative"
                                defaultValue=""
                                id="input-username"
                                placeholder="OpenStack username"
                                type="text"
                                onChange={event => setOpenstackUsername(event.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="password"><i className="ni ni-badge mr-2"/>Password</Label>
                            <Input
                                className="form-control-alternative"
                                defaultValue=""
                                id="input-password"
                                placeholder="OpenStack password"
                                type="password"
                                onChange={event => setOpenstackPassword(event.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="authUrl"><i className="ni ni-badge mr-2"/>Auth URL</Label>
                            <Input
                                className="form-control-alternative"
                                defaultValue=""
                                id="input-auth-url"
                                placeholder="OpenStack Auth url"
                                type="text"
                                onChange={event => setOpenstackAuthURL(event.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="projectId"><i className="ni ni-badge mr-2"/>Project ID</Label>
                            <Input
                                className="form-control-alternative"
                                defaultValue=""
                                id="input-project-id"
                                placeholder="OpenStack Project ID"
                                type="text"
                                onChange={event => setOpenstackProjectId(event.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="domainId"><i className="ni ni-badge mr-2"/>Domain ID</Label>
                            <Input
                                className="form-control-alternative"
                                defaultValue=""
                                id="input-domain-id"
                                placeholder="Domain ID"
                                type="text"
                                onChange={event => setOpenstackDomain(event.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="network"><i className="ni ni-badge mr-2"/>Network</Label>
                            <Input
                                className="form-control-alternative"
                                defaultValue=""
                                id="input-network"
                                placeholder="OpenStack Network"
                                type="text"
                                onChange={event => setOpenstackNetwork(event.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                    <Row>
                        {renderOpenstackSaveButton()}
                    </Row>
            </>
        );
    }

    function renderConnectGcp() {
        return (
            <>
                <Row>
                    <div className="icon rounded-circle mr-4 icon-lg">
                        <i className="fab fa-google"/>
                    </div>
                    <p className="text-sm text-muted">
                        Connect GCP to be able to create self-hosted runners on your GCP
                        account. Create a service account with Permissions to compute and generate
                        a JSON key and save them here.
                    </p>
                </Row>
                <Row>
                    <Col lg="12">
                        <FormGroup>
                            <Label for="accessKey"><i className="ni ni-badge mr-2"/>Service Account JSON</Label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={userInfo.gcp_key}
                                id="input-service-account-key"
                                placeholder="{..}"
                                type="textarea"
                                onChange={event => setGcpServiceAccountJson(event.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    {renderGcpSaveButton()}
                </Row>


            </>
        );
    }

    function renderConnectOracle() {
        return (
            <>
                <Row>
                    <div className="icon rounded-circle mr-4 icon-lg">
                        <i className="fa fa-cloud"></i>
                    </div>
                    <p className="text-sm text-muted">
                        Connect Oracle Cloud to be able to create self-hosted runners on your Oracle
                        account. Create a permissions config and private key and save them here.
                    </p>
                </Row>
                <Row>
                    <Col lg="12">
                        <FormGroup>
                            <Label for="oracle-config"><i className="ni ni-badge mr-2"/>Config Auth</Label>
                            <Input
                                className="form-control-alternative"
                                id="input-oracle-config"
                                placeholder={defaultOracleConfig}
                                type="textarea"
                                onChange={event => setOracleConfig(event.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <FormGroup>
                            <Label for="privateKey"><i className="ni ni-badge mr-2"/>Private Key</Label>
                            <Input
                                className="form-control-alternative"
                                // defaultValue={userInfo.gcp_key}
                                id="input-oracle-private-key"
                                placeholder={defaultPrivateKey}
                                type="textarea"
                                onChange={event => setOraclePrivateKey(event.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    {renderOracleSaveButton()}
                </Row>


            </>
        );
    }


    return (
        <>
            <CloudHeader userInfo={userInfo}/>
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>

                    <Col className="order-xl-1" xl="8">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Cloud Credentials</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <h6 className="heading-small text-muted mb-4">
                                        DigitalOcean
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    {renderConnectDO()}
                                                </FormGroup>

                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                                <DropdownItem divider />
                                <Form>
                                    <h6 className="heading-small text-muted mb-4">
                                        Amazon Web Services
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    {renderConnectAws()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                                <DropdownItem divider />
                                <Form>
                                    <h6 className="heading-small text-muted mb-4">
                                        Google Cloud Platform
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="10">
                                                <FormGroup>
                                                    {renderConnectGcp()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                                <DropdownItem divider />
                                <Form>
                                    <h6 className="heading-small text-muted mb-4">
                                        Microsoft Azure
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    {renderConnectAzure()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                                <DropdownItem divider />
                                <Form>
                                    <h6 className="heading-small text-muted mb-4">
                                        Openstack
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    {renderConnectOpenstack()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                                <DropdownItem divider />
                                <Form>
                                    <h6 className="heading-small text-muted mb-4">
                                        Oracle Cloud
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    {renderConnectOracle()}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default Cloud;
